import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "trademarks-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`The following are trademarks of Maintenance Connection
Canada`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`MCxLE`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MCe`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MCeLE`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintenance Connection Everywhere`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MCC`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintenance Connection Canada`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MCEverywhere Maintenance Connection Express Limited Internet Edition`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintenance Connection Express Luxury Edition`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintenance Connection Everywhere Luxury Edition`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`APS`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`And in Canada`}</strong></p>
    <ul>
      <li parentName="ul">{`Maintenance Connection`}</li>
    </ul>
    <p>{`Maintenance Connection Canada is a division of Asset Pro Solutions Inc. `}</p>
    <p>{`"Tigre de Soleil" is a trademark of "Tigre de Soleil, Inc."`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      